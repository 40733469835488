.main-navbar {
    min-height: calc(118rem/16);
    width: 100%;
    z-index: 999;
    position: relative;
    @media screen and (max-width: 767px) {
        min-height: calc(120rem/16);
    }
}
.main-navbar-nav {
    background: rgba(0, 0, 0, 0.51);
    height: calc(118rem/16);
    @media screen and (max-width: 767px) {
        height: calc(60rem/16);
        background: rgba(0, 0, 0, 0.65);
    }
}
.main-navbar-meta {
    height: calc(60rem/16);
    width: 100%;
    background-color: var(--color-secondary);
    color: var(--color-secondary-contrast);
    position: relative;
    z-index: 10;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.main-navbar-meta--meta-link {
    height: auto;
    padding: .375rem 0;
    font-size: .8rem;

    @media screen and (max-width: 767px) {
        padding-bottom: 0;
    }
}
.main-navbar-meta--meta-link a {
    text-decoration: underline;
}
.main-navbar-meta__list {
    display: flex;
    justify-content: center;
}
.main-navbar:before {
    width: 40%;
    content:'';
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    z-index: 8;
    background: linear-gradient(270deg, rgba(32,32,32,0) 0%, #202020 100%);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-navbar:after {
    width: 40%;
    content:'';
    position: absolute;
    top:0;
    bottom: 0;
    right:0;
    z-index: 8;
    background: linear-gradient(90deg, rgba(32,32,32,0) 0%, #202020 100%);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-navbar--fixed {
    position: fixed;
    left:0;
    right:0;
    top:0;
}
.main-navbar__container {
    display: flex;
    z-index: 10;
    position: relative;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
        height: 100%;
    }
}
.main-navbar__brand {
    /*max-width: calc(120rem/16);*/
    width: auto;
    height: auto;
    @media screen and (max-width: 767px) {
        max-width: calc(115rem/16);
        max-height: calc(50rem/16);
        height: auto;
        width: auto;
    }
    @media screen and (min-width: 768px){
        max-height: calc(102rem/16);
        max-width: calc(302rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1300px){
        max-width: calc(220rem/16);
        max-height: calc(90rem/16);
        width: auto;
    }
}

@media print {
    .main-navbar__brand {
        max-width: calc(220rem/16);
        max-height: calc(90rem/16);
    }
}

.main-navbar__brand--side{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.main-navbar__brand--cinestyria {
    @media screen and (max-width: 767px) {
        max-width: calc(150rem/16);
        max-height: calc(34rem/16);
        height: auto;
        width: auto;
    }
    @media screen and (min-width: 1599px) {
        max-width: none;
    }
}

.main-navbar__centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    pointer-events: none;
    flex-grow: 1;

    @media screen and (min-width: 1700px){
        position: absolute;
        left:0;
        right:0;
    }
    
    @media screen and (max-width: 767px){
        top: 4px;
        position: relative;
    }
}

.main-navbar__centered--side{
    position: relative;
}
.main-navbar__left {
    display: flex;
}
.main-navbar__right {
    display: flex;
    @media screen and (max-width: 767px) {
        z-index: 999;
    }
}
.main-navbar__cinestyria-logo {
    justify-content: left;
    left: calc(160rem/16);
    @media screen and (max-width: 767px) {
        justify-content: center;
        left: 0;
    }
}
.main-navbar__list {
    display: flex;
    margin-right: calc(10rem/16);
    @media screen and (max-width: 767px) {
        margin-right:0;
    }
}
.main-navbar__regions {
    background-color: transparent;
    border: none;
    color:#fff;
    text-transform: uppercase;
    font-family: var(--font-decorative);
    letter-spacing: calc(3.5rem/16);
    padding: calc(20rem/16) calc(50rem/16);
    transition: background-color 0.3s ease;
    @media screen and (max-width: 767px) {
        padding: calc(16rem/16);
        font-size: calc(20rem/16);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
.main-navbar__regions-text,  .main-navbar__regions-arrow-down{
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.main-navbar__regions .icon-pin-regions {
    transition: transform 0.3s ease;
}


.main-navbar__item__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color:#fff;
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
    font-size: calc(14rem/16);
    background-color: transparent;
    border: none;
    box-shadow: none;
    @media screen and (max-width: 1199px) {
        font-size: calc(10rem/16);
    }
    @media(max-width: 1100px){
        padding-right: 0;
        padding-left: 0.9rem;
    }
}
.main-navbar__item__link:active, .main-navbar__item__link:focus {
    color:#fff;
}
.main-navbar__item:not(.main-navbar__item--search) {
    transition: transform 0.2s ease;
    -webkit-backface-visibility: hidden;
}
.main-navbar__item__icon {
    margin-top: calc(5rem/16);
    font-size: calc(27rem/16);
    margin-bottom: calc(5rem/16);
    height: calc(28rem/16);
    display: flex;
    align-items: flex-end;
    transition: transform 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.main-navbar__item__icon.icon-search {
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.main-navbar__item__icon.icon-cart {
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.main-navbar__item-text {
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.main-navbar__booking-btn, .main-navbar__adventure-btn, .main-navbar__primary-btn {
    background-color: var(--color-primary-light);
    color:#fff;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: calc(8rem/16);
    font-size: calc(12rem/16);
    transition: 0.2s ease background-color;
    @media screen and (min-width: 1400px) {
        padding: calc(11rem/16);
        font-size: calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(60rem/16);
        padding: calc(11rem/16);
    }
}
.main-navbar__cart-btn {
    background-color: var(--color-secondary);
    color:#fff;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: calc(8rem/16);
    font-size: calc(12rem/16);
    transition: 0.2s ease background-color;
    @media screen and (min-width: 1400px) {
        padding: calc(11rem/16);
        font-size: calc(15rem/16);
        width: calc(118rem/16);
    }
    @media screen and (max-width: 767px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
        padding: calc(11rem/16);
        background-color: transparent;
    }
}
.main-navbar__cart-btn .main-navbar__item-count {
    right: calc(-8rem/16);
}
.main-navbar__primary-btn {
    @media screen and (min-width: 1200px) {
        min-width: calc(118rem/16);
    }
}
.main-navbar__booking-btn__icon, .main-navbar__adventure-btn__icon, .main-navbar__primary-btn .main-navbar__primary-btn__icon, .main-navbar__cart-btn__icon {
    margin-bottom: calc(10rem/16);
    font-size: calc(22rem/16);
    transition: transform 0.2s ease;
    @media screen and (max-width: 767px) {
        margin-bottom:0;
        font-size: 1.2rem;
    }
}
.main-navbar__item-count {
    width: calc(17rem/16);
    height: calc(17rem/16);
    background-color: var(--color-primary-light);
    color:#fff;
    border-radius: 50%;
    position: absolute;
    bottom:0;
    right: calc(-5rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(9rem/16);
}
@media (hover: hover) and (pointer:fine) {
    .main-navbar__booking-btn:hover, .main-navbar__adventure-btn:hover, .main-navbar__primary-btn:hover {
        background-color: var(--color-primary-light-light);
        color:#fff;
    }
    .main-navbar__cart-btn:hover, .main-navbar__cart-btn:hover {
        background-color: var(--color-secondary-light);
        color:#fff;
    }
    .main-navbar__booking-btn:hover .main-navbar__booking-btn__icon, .main-navbar__adventure-btn:hover .main-navbar__adventure-btn__icon, .main-navbar__cart-btn:hover .main-navbar__cart-btn__icon, .main-navbar__primary-btn:hover .main-navbar__primary-btn__icon {
        transform: translateY(4px) scale(1.1) rotate(-2deg);
    }
    .main-navbar__regions:hover .icon-pin-regions {
        transform: scale(1.5);
    }
    .main-navbar__regions:hover {
        background-color: rgba(0,0,0,0.2);
    }
    .main-navbar__item:not(.main-navbar__item--search):hover {
        color:#fff;
        transform: scale(1.05);
    }
    .main-navbar__item:hover .main-navbar__item__icon {
        color:#fff;
        transform: translateY(-5px) scale(1.1) rotate(-2deg);
    }
}
.nav-not-affix .main-navbar-meta {
    display: none;
}
@media screen and (max-width: 787px) {
    .main-navbar__item__link--search {
        padding-bottom: calc(8rem/16);
    }
}
