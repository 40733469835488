.avalanche-icon-container {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.avalanche-icon-container:before {
    content: '';
    display: block;
    padding-top: 61%;
}
.avalanche-icon-container .avalanche-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-size: auto calc(180/35*100%); /* svg height / svg icon height * 100 */
}
.avalanche-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('/static/debug/img/avalanche-icons/sprite.svg');
}
.avalanche-icon-stufe_4-1z {
    width: 57px;
    height: 35px;
    background-position: 0 0;
}
.avalanche-icon-stufe_4-2m {
    width: 55px;
    height: 35px;
    background-position: -74px 0;
}
.avalanche-icon-stufe_4-2z {
    width: 57px;
    height: 35px;
    background-position: -148px 0;
}
.avalanche-icon-stufe_4-3m {
    width: 55px;
    height: 35px;
    background-position: -222px 0;
}
.avalanche-icon-stufe_4-3z {
    width: 57px;
    height: 35px;
    background-position: -296px 0;
}
.avalanche-icon-stufe_4-5m {
    width: 59px;
    height: 35px;
    background-position: -370px 0;
}
.avalanche-icon-stufe_4-5z {
    width: 59px;
    height: 35px;
    background-position: -444px 0;
}
.avalanche-icon-stufe_5 {
    width: 59px;
    height: 35px;
    background-position: -518px 0;
}
.avalanche-icon-stufe_5-4m {
    width: 59px;
    height: 35px;
    background-position: 0 -37px;
}
.avalanche-icon-stufe_5-4z {
    width: 59px;
    height: 35px;
    background-position: -74px -37px;
}
.avalanche-icon-tagesgang {
    width: 57px;
    height: 35px;
    background-position: -148px -37px;
}
.avalanche-icon-hoehenabhaengigkeit {
    width: 57px;
    height: 35px;
    background-position: -222px -37px;
}
.avalanche-icon-stufe_1 {
    width: 57px;
    height: 35px;
    background-position: -296px -37px;
}
.avalanche-icon-stufe_1-2m {
    width: 55px;
    height: 35px;
    background-position: -370px -37px;
}
.avalanche-icon-stufe_1-2z {
    width: 57px;
    height: 35px;
    background-position: -444px -37px;
}
.avalanche-icon-stufe_1-3m {
    width: 55px;
    height: 35px;
    background-position: -518px -37px;
}
.avalanche-icon-stufe_1-3z {
    width: 57px;
    height: 35px;
    background-position: 0 -74px;
}
.avalanche-icon-stufe_1-4m {
    width: 55px;
    height: 35px;
    background-position: -74px -74px;
}
.avalanche-icon-stufe_1-4z {
    width: 57px;
    height: 35px;
    background-position: -148px -74px;
}
.avalanche-icon-stufe_2 {
    width: 57px;
    height: 35px;
    background-position: -222px -74px;
}
.avalanche-icon-stufe_2-1m {
    width: 55px;
    height: 35px;
    background-position: -296px -74px;
}
.avalanche-icon-stufe_2-1z {
    width: 57px;
    height: 35px;
    background-position: -370px -74px;
}
.avalanche-icon-stufe_2-3m {
    width: 55px;
    height: 35px;
    background-position: -444px -74px;
}
.avalanche-icon-stufe_2-3z {
    width: 57px;
    height: 35px;
    background-position: -518px -74px;
}
.avalanche-icon-stufe_2-4m {
    width: 55px;
    height: 35px;
    background-position: 0 -111px;
}
.avalanche-icon-stufe_2-4z {
    width: 57px;
    height: 35px;
    background-position: -74px -111px;
}
.avalanche-icon-stufe_3 {
    width: 57px;
    height: 35px;
    background-position: -148px -111px;
}
.avalanche-icon-stufe_3-1m {
    width: 55px;
    height: 35px;
    background-position: -222px -111px;
}
.avalanche-icon-stufe_3-1z {
    width: 57px;
    height: 35px;
    background-position: -296px -111px;
}
.avalanche-icon-stufe_3-2m {
    width: 55px;
    height: 35px;
    background-position: -370px -111px;
}
.avalanche-icon-stufe_3-2z {
    width: 57px;
    height: 35px;
    background-position: -444px -111px;
}
.avalanche-icon-stufe_3-4m {
    width: 55px;
    height: 35px;
    background-position: -518px -111px;
}
.avalanche-icon-stufe_3-4z {
    width: 57px;
    height: 35px;
    background-position: 0 -148px;
}
.avalanche-icon-stufe_3-5z {
    width: 59px;
    height: 35px;
    background-position: -74px -148px;
}
.avalanche-icon-stufe_4 {
    width: 57px;
    height: 35px;
    background-position: -148px -148px;
}
.avalanche-icon-stufe_4-1m {
    width: 55px;
    height: 35px;
    background-position: -222px -148px;
}

